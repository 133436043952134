import React from 'react'
import { useTranslation } from "react-i18next";

import { useTheme } from '@material-ui/core/styles'
import SectionHeader from './SectionHeader'

export default function SupportList() {
  const { t } = useTranslation();
  const { spacing, palette } = useTheme()

  return (
    <div style={{ background: palette.background.paper }}>
      <div>
        <SectionHeader primary={t("Heading.SupportedAppsDevice")} />
        <div style={{padding: spacing(6)}}>
          <li>{t("Word.RuntripApp")}</li>
          <li>Apple Watch</li>
          <li>GARMIN</li>
          <li>Suunto</li>
          <li>Polar</li>
          <li>Fitbit</li>
          <li>adidas Running</li>
          <li>COROS</li>
        </div>
      </div>
    </div>
  )
}
