import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import "./i18n/config.ts";

import { useTheme } from '@material-ui/core/styles'

import AppBar from 'components/AppBar'
import Container from 'components/Container'
import Certificate from 'pages/Certificate'
import Entry from 'pages/Entry'
import EntryComplete from 'pages/EntryComplete'
import Error from 'pages/Error'
import Event from 'pages/Event'
import EventList from 'pages/EventList'
import Login from 'pages/Login'
import MyPage from 'pages/MyPage'
import News from 'pages/News'
import NewsList from 'pages/NewsList'
import Policy from 'pages/Policy'
import PrivilegeList from 'pages/PrivilegeList'
import SignUp from 'pages/SignUp'
import Terms from 'pages/Terms'
import Top from 'pages/Top'

export default function App() {
  const { palette } = useTheme()
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        background: palette.background.default,
      }}
    >
      <AppBar />
      <Container>
        <Switch>
          <Route exact path="/" component={Top} />
          <Route path="/events/:id/activities/me" component={Certificate} />
          <Route path="/events/:id/privileges/me" component={PrivilegeList} />
          <Route path="/events/:id/entry/complete" component={EntryComplete} />
          <Route path="/events/:id/entry" component={Entry} />
          <Route path="/events/:id" component={Event} />
          <Route path="/events" component={EventList} />
          <Route path="/users/me" component={MyPage} />
          <Route path="/signup" component={SignUp} />
          <Route path="/login" component={Login} />
          <Route path="/news/:id" component={News} />
          <Route path="/news" component={NewsList} />
          <Route path="/terms" component={Terms} />
          <Route path="/privacypolicy" component={Policy} />
          <Route path="/404" component={Error} />
          <Redirect to="/" />
        </Switch>
      </Container>
    </div>
  )
}
