import React, { useState } from 'react'
import { useTranslation } from "react-i18next";

import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'
import { useTheme } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'

import { useAuth } from 'hooks/useAuth'

export default function Logout() {
  const { t } = useTranslation();
  const { palette, spacing } = useTheme()
  const { user, signOut } = useAuth()
  const [visibleSnackBar, setVisibleSnackBar] = useState(false)

  return (
    <>
      {user && (
        <>
          <div style={{ width: '100%', height: 1, background: palette.divider }} />
          <div style={{ margin: spacing(6, 0), textAlign: 'center' }}>
            <Button
              style={{ color: palette.link, fontSize: 12 }}
              variant="text"
              onClick={() => {
                signOut()
                setVisibleSnackBar(true)
              }}
            >
                {t("Word.Logout")}
            </Button>
          </div>
        </>
      )}
      <Snackbar
        open={visibleSnackBar}
        autoHideDuration={3000}
        onClose={() => setVisibleSnackBar(false)}
      >
        <Alert onClose={() => setVisibleSnackBar(false)} severity="success">
          {t("Word.LoggedOut")}
        </Alert>
      </Snackbar>
    </>
  )
}
