import React, { useEffect } from 'react'
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom'

import { useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import EventListItem from 'components/EventListItem'
import Guide from 'components/Guide'
import SectionHeader from 'components/SectionHeader'
import { useAuth } from 'hooks/useAuth'
import { EventState, useEvent } from 'hooks/useEvent'
import Information from 'components/Information'
import CustomButton from 'components/CustomButton'

export default function MyPage() {
  const { t } = useTranslation();
  const { spacing, palette } = useTheme()
  const { participantResources, getParticipants } = useEvent()
  const { user } = useAuth()
  const { push } = useHistory()

  useEffect(() => {
    if (user) {
      getParticipants()
    }
  }, [user, getParticipants])

  if (user === null) {
    push('/login')
    return null
  }

  const past = participantResources.filter(({ event }) =>
    [EventState.FINISHED, EventState.UPLOAD_OPENING].includes(event.state)
  )
  const future = participantResources.filter(
    ({ event }) => ![EventState.FINISHED, EventState.UPLOAD_OPENING].includes(event.state)
  )

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: spacing(6, 3),
          background: palette.background.paper,
          marginBottom: spacing(6),
        }}
      >
        <Typography style={{ fontWeight: 'bold', marginBottom: spacing(2) }} variant="body1">
          {user.name}
        </Typography>
        <Typography style={{ marginBottom: spacing(6) }} variant="caption">
            {t("Word.MemberId")}：{user.id}
        </Typography>
        {Boolean(process.env.REACT_APP_ACCOUNT_ID === '1') && (
        <div>
          <div style={{ display: 'flex' }}>
            <Typography variant='body1' style={{ marginRight: spacing(2) }} color='textPrimary'>
                {t("Word.Participation")}
            </Typography>
            <Typography variant='body1' style={{ fontWeight: 'bold' }}>
                {user.total_participant_month_count}
            </Typography>
            <div style={{ width: 1, margin: spacing(0, 2), background: palette.divider }} />
            <Typography variant='body1' style={{ marginRight: spacing(2) }} color='textPrimary'>
                {t("Word.CumulativeDistance")}
            </Typography>
            <Typography variant='body1' style={{ fontWeight: 'bold' }}>
              {parseInt(user.total_activity_distance / 10) / 100}km
            </Typography>
          </div>
        </div>
        )}
      </div>
      <div>
          <Information text={t("Message.MyPage.AppInstallationGuide")} />
          <div style={{ padding: spacing(3) }}>
            <CustomButton
              style={{ marginBottom: spacing(6) }}
              onClick={() => window.open(`https://runtrip-web.onelink.me/Pprx/vrwctop`)}
              arrow
            >
                {t("Word.InstallRuntripForFree")}
            </CustomButton>
          </div>
        </div>
      {user.total_activity_distance === 0 && <Guide style={{ marginBottom: spacing(6) }} />}
      {future.length > 0 && (
        <div style={{ background: palette.background.paper, marginBottom: spacing(6) }}>
          <SectionHeader primary={t("Heading.EventsToAttend")} />
          {future.map(resource => (
            <EventListItem key={resource.event.id} eventResource={resource} enableCertificate />
          ))}
        </div>
      )}
      {past.length > 0 && (
        <div style={{ background: palette.background.paper }}>
          <SectionHeader primary={t("Heading.EventsAttendedInThePast")} />
          {past.map(resource => (
            <EventListItem key={resource.event.id} eventResource={resource} enableCertificate />
          ))}
        </div>
      )}
      {user.total_activity_distance > 0 && <Guide style={{ marginTop: spacing(6) }} />}
    </div>
  )
}
