import React, { useCallback, useState } from 'react'
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from 'react-router-dom'

import { useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import CustomButton from 'components/CustomButton'
import Popup from 'components/Popup'
import { useAPI } from 'hooks/useAPI'
import { EntryState, EventState, ParticipantStatus } from 'hooks/useEvent'

export default function EventAction({ eventResource, user, onUpdateEvent }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false)
  const { palette, spacing } = useTheme()
  const { id } = useParams()
  const { push } = useHistory()
  const { client } = useAPI()
  const { event, participant } = eventResource || {}
  const xs = useMediaQuery(({ breakpoints }) => breakpoints.only('xs'))
  const [cancelSucceeded, setCancelSucceeded] = useState(false)
  const [cancelFailed, setCancelFailed] = useState(false)

  const cancel = useCallback(() => {
    setOpen(false)
    client
      .delete(`/vrwc/event_participant/${participant.id}`)
      .then(res => {
        onUpdateEvent(res.data)
        setCancelSucceeded(true)
      })
      .catch(() => setCancelFailed(true))
  }, [client, participant, onUpdateEvent])

  const Button = ({ children, caption, disabled, onClick, variant = 'contained' }) => {
    if (disabled || !xs) {
      return (
        <div style={{ padding: spacing(3, 0) }}>
          {caption && (
            <Typography style={{ margin: spacing(6, 0) }} variant="body2">
              {caption}
            </Typography>
          )}
          <CustomButton variant={variant} disabled={disabled} onClick={onClick}>
            {children}
          </CustomButton>
        </div>
      )
    }

    return (
      <div
        style={{
          position: 'fixed',
          right: 0,
          left: 0,
          bottom: 0,
          padding: spacing(3),
          zIndex: 100,
        }}
      >
        <CustomButton variant={variant} onClick={onClick}>
          {children}
        </CustomButton>
      </div>
    )
  }

  const Action = useCallback(() => {
    switch (event.state) {
      // イベント開催前
      case EventState.BEFORE:
        // エントリー開始前
        if (event.entryState === EntryState.BEFORE) {
          return (
            <Button disabled caption="このイベントは申し込み受付前です">
              {t("Word.AttendThisEvent")}
            </Button>
          )
        } else if (event.entryState === EntryState.CLOSED) {
          return null
        }

        // 未ログインの場合
        if (!user) {
          const entryPath = encodeURIComponent(`/events/${id}/entry`)
          return (
            <Button variant="contained" onClick={() => push(`/login?continue=${entryPath}`)}>
              {t("Word.AttendThisEvent")}
            </Button>
          )
        }

        // 参加済みの場合
        if (participant.status === ParticipantStatus.REGISTERED) {
          return (
            <Button variant="outlined" onClick={() => setOpen(true)}>
              {t("Word.CancelParticipation")}
            </Button>
          )
        }

        // 参加人数上限に達した場合
        if (event.participant_count >= event.participant_count_max) {
          return null
        }

        return (
          <Button variant="contained" onClick={() => push(`/events/${id}/entry`)}>
            {t("Word.AttendThisEvent")}
          </Button>
        )

      case EventState.OPENING:
      case EventState.UPLOAD_OPENING:
        // 未ログインの場合はログインしてイベントページへ戻ってもらう
        if (!user) {
          const eventPath = encodeURIComponent(`/events/${id}`)
          return (
            <Button variant="contained" onClick={() => push(`/login?continue=${eventPath}`)}>
              ログインする
            </Button>
          )
        }

        // エントリー済み
        if (participant.status === ParticipantStatus.REGISTERED) {
          return (
              <Button variant="outlined" onClick={() => window.open(`https://runtrip-web.onelink.me/Pprx/EntryCompleted`)}>
                {t("Word.UploadResultsInApp")}
              </Button>
          )
        }

        // エントリー期間中で参加人数上限に達していない場合
        if (
          event.entryState === EntryState.OPENING &&
          event.participant_count < event.participant_count_max
        ) {
          return (
            <Button variant="contained" onClick={() => push(`/events/${id}/entry`)}>
              {t("Word.AttendThisEvent")}
            </Button>
          )
        }

        return null
      default:
        return null
    }
  }, [event, id, participant, user, push, palette, xs]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Action />
      <Popup
        open={open}
        onClose={() => setOpen(false)}
        title={t("Word.CancelApplication")}
        description={t("Message.Event.CancelConfirmation")}
        primaryText={t("Word.Yes")}
        onClickPrimary={cancel}
        secondaryText={t("Word.No")}
        onClickSecondary={() => setOpen(false)}
        buttonDirection="row"
      />
      {cancelSucceeded && (
        <Popup
          id="event_cancel"
          open
          onClose={() => setCancelSucceeded(false)}
          title={t("Word.CancelApplication")}
          description={t("Message.Event.CanceledMessage")}
          primaryText="OK"
          onClickPrimary={() => setCancelSucceeded(false)}
        />
      )}
      {cancelFailed && (
        <Popup
          open
          onClose={() => setCancelFailed(false)}
          title={t("Word.AnErrorHasOccurred")}
          description={t("Message.Event.CancelFailedMessage")}
          primaryText="OK"
          onClickPrimary={() => setCancelFailed(false)}
        />
      )}
    </>
  )
}
