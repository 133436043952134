import React from 'react'
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom'

import { useTheme } from '@material-ui/core/styles'

import AboutUs from 'components/AboutUs'
import Concept from 'components/Concept'
import CustomButton from 'components/CustomButton'
import FAQ from 'components/FAQ'
import SupportList from 'components/SupportList'
import HowItWorks from 'components/HowItWorks'
import MainImage from 'components/MainImage'
import Reward from 'components/Reward'
import EventList from 'pages/EventList'
import NewsList from 'pages/NewsList'
import Typography from "@material-ui/core/Typography";
import WarningRoundedIcon from "@material-ui/icons/Info";
import MultilineText from "../components/MultilineText";
import Button from "@material-ui/core/Button";

export default function Top() {
  const { t } = useTranslation();
  const { palette, spacing } = useTheme()
  const { push } = useHistory()

  const More = ({ text, href }) => (
    <div
      style={{
        marginBottom: spacing(6),
        background: palette.background.paper,
        padding: spacing(3),
      }}
    >
      <CustomButton
        arrow
        onClick={() => {
          if (href.startsWith('/')) {
            push(href)
          } else {
            window.open(href)
          }
        }}
      >
        {text}
      </CustomButton>
    </div>
  )

  return (
    <div style={{ background: palette.background.default }}>
      <MainImage src="/images/main.jpg" alt="トップ" type="top" />
        <div>
            {Boolean(process.env.REACT_APP_ACCOUNT_ID === '1') && (
                <div style={{background: palette.background.paper}}>
                    <div style={{
                        margin: spacing(3, 3),
                        padding: spacing(3),
                        background: "#E6FAFF",
                        border: "1px solid #42B8E4",
                        borderRadius: "8px",
                    }}>
                        <Typography variant="body2">
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div style={{display: 'flex'}}>
                                    <WarningRoundedIcon style={{color: '#42B8E4', marginRight: spacing(3),}}/>
                                </div>
                                <div>
                                    <div style={{marginTop: spacing(2)}}>
                                        <MultilineText>Runtripアプリから完走・完歩証が発行できない場合（Runtripアプリをインストールできない場合等）は、こちらのフォームから申請ください。</MultilineText>
                                    </div>
                                    <Button
                                        style={{marginTop: spacing(2), marginLeft: 0, padding: 0}}
                                        color="secondary"
                                        onClick={() => window.open("https://req.qubo.jp/runtrip/form/vrwc")}
                                    >計測データ審査フォーム
                                    </Button>
                                </div>
                            </div>
                        </Typography>
                    </div>
                </div>
            )}
        </div>
      <Concept />
      {Boolean(process.env.REACT_APP_CONCEPT_HREF) && (
        <More text="詳細を見る" href={process.env.REACT_APP_CONCEPT_HREF} />
      )}
      <NewsList limit={3} />
      <More text={t("Word.NewsList")} href="/news" />
      <HowItWorks />
      {process.env.REACT_APP_ACCOUNT_ID === '37' && (
      <div>
          <img style={{objectFit: 'contain', width: '100%', marginBottom: spacing(5)}} alt="" src={"/images/top-step-up.jpg"}/>
      </div>
      )}
      <Reward />
      <EventList limit={7} />
      <More text={t("Word.EventsList")} href="/events" />
      <FAQ />
      {process.env.REACT_APP_ACCOUNT_ID !== '37' && (
        <SupportList/>
      )}
      <AboutUs />
    </div>
  )
}
