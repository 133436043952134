import React from 'react'

export default function GiftBox({color}) {
    return (
        <svg width='95' height='60' viewBox="0 0 168.65 115.94" xmlns="http://www.w3.org/2000/svg">
            <g>
                <g>
                    <path
                        d="M40.04,53.93h91.89c.11,0,.22-.01,.33-.02,2.65-.23,4.67-2.33,4.73-5.04-.22-3.92,1.31-9.7-3.46-11.38-19.12-.74-38.43,0-57.61-.25-11.8,0-23.59,0-35.39,0-5.25-.57-7.63,2.58-7.07,7.61-.5,4.79,.3,8.63,5.04,9.06,.48,.04,.98,.06,1.54,.03Z"
                        fill={`#fff`}/>
                    <path
                        d="M43.92,59.98c0,12.6,0,25.21,0,37.81,.3,2.95-.81,6.32,.94,8.93,1.65,2.01,4.76,1.5,7.09,1.5H122.38c2.45-.07,4.13-1.95,4.16-4.36,.1-14.63-.04-29.26,0-43.88h-47.03c-11.86,0-23.72,0-35.58,0Z"
                        fill={`#fff`}/>
                    <path
                        d="M143.04,45.14c.84-7.02-3.58-13.94-11.12-13.94-15.01-.07-30.02,.04-45.03,0-10.52,0-21.04,0-31.56,0-10.16,.37-27.48-3.43-27.91,11.44-.82,9.02,1.53,16.37,10.36,17.28-.09,3.24,.12,6.57,.1,9.4,.59,12.83-1.25,26.07,.82,38.7,2.77,7.04,10.78,6.42,17.04,6.24,11.88,0,23.76,0,35.65,0h29.4c5.91,.53,11.48-3.63,11.76-9.78,.05-6.03,0-12.06,.01-18.09,0-8.81,.12-17.63,.03-26.44,7.52-.56,11.33-7.38,10.43-14.81Zm-103,8.8c-.56,.03-1.06,0-1.54-.03-4.73-.42-5.53-4.27-5.04-9.06-.55-5.02,1.83-8.18,7.07-7.61,10.93,0,21.85,0,32.78,0v16.7H40.04Zm11.91,54.28c-2.33,0-5.44,.51-7.09-1.5-1.75-2.61-.64-5.98-.94-8.93,0-12.6,0-25.21,0-37.81,9.8,0,19.59,0,29.39,0v48.24h-21.37Zm74.59-4.36c-.02,2.4-1.71,4.29-4.16,4.36h-25.23V59.98h29.39c-.04,14.63,.1,29.26,0,43.88Zm10.46-54.99c-.06,2.71-2.08,4.81-4.73,5.04-.11,0-.22,.02-.33,.02h-34.79v-16.7c12.15-.09,24.31-.21,36.39,.26,4.78,1.68,3.24,7.46,3.46,11.38Z"
                        fill={color}/>
                    <path d="M111.32,16.45s0,.12,.03,.21c0-.07-.02-.14-.03-.21Z" fill={color}/>
                    <path d="M106.82,13.02s-.03,0-.04,0c.07,.01,.13,.02,.17,.02-.04,0-.08-.02-.13-.02Z"
                          fill={color}/>
                    <path d="M111.11,18.14s0-.02,0-.03c-.04,.08-.06,.13-.08,.17,.02-.05,.04-.09,.07-.13Z"
                          fill={color}/>
                    <path d="M59.11,17.13c.04,.11,.08,.23,.1,.37,.02-.04,0-.16-.1-.37Z" fill={color}/>
                    <path d="M59.16,16.36c-.03,.11-.04,.24-.05,.34,.03,.12,.13,0,.05-.34Z" fill={color}/>
                    <path
                        d="M117.18,14.97c-.8-3.83-4.27-6.65-7.93-7.6-11.86-2.66-19.35,9.79-24.02,18.86-21.38-45.78-59.84,4.01,0,8,11.08-.78,33.2-4.47,31.95-19.27Zm-54.3,6.94c-.91-.51-2.27-1.87-2.76-2.48-.42-.6-.82-1.18-.89-1.93-.06,.15-.09-.32-.14-.45,.02,.03,.02,.05,.04,.08-.01-.3,0-.53,.03-.85,0,.03,.01,.06,.02,.09,.46-1.86,2.32-2.91,4.09-3.27,7.57-.97,12.52,8.75,15.87,14.41-5.7-1.15-11.28-2.36-16.25-5.6Zm48.47-5.25c.01,.4-.1,1.11-.23,1.46,.02-.04,.04-.08,.07-.13-.04,.05-.2,.48-.15,.3-4.15,6.38-12.77,7.62-19.68,9.23,2.93-5.22,6.21-10.89,11.52-13.84,1.09-.51,2.63-.78,3.88-.64-.04,0-.09-.02-.15-.03,.09,.03,.61,.07,.32,.06,1.83,.28,3.6,1.28,4.33,3.04-.48-.82-.02-.05,.05,.36,.01-.26,.03,.29,.07,.38-.02-.06-.03-.12-.05-.17Z"
                        fill={color}/>
                    <path
                        d="M155.11,15.93c-7.85,2.03-10.54,4.72-12.57,12.57-.32,1.25-2.09,1.25-2.41,0-2.03-7.85-4.72-10.54-12.57-12.57-1.25-.32-1.25-2.09,0-2.41,7.85-2.03,10.54-4.72,12.57-12.57,.32-1.25,2.09-1.25,2.41,0,2.03,7.85,4.72,10.54,12.57,12.57,1.25,.32,1.25,2.09,0,2.41Z"
                        fill={color}/>
                    <path
                        d="M168.04,36.58c-5.11,1.32-6.87,3.08-8.19,8.19-.21,.82-1.36,.82-1.57,0-1.32-5.11-3.08-6.87-8.19-8.19-.82-.21-.82-1.36,0-1.57,5.11-1.32,6.87-3.08,8.19-8.19,.21-.82,1.36-.82,1.57,0,1.32,5.11,3.08,6.87,8.19,8.19,.82,.21,.82,1.36,0,1.57Z"
                        fill={color}/>
                    <path
                        d="M34.27,97.07c-5.01,1.3-6.73,3.01-8.02,8.02-.21,.8-1.33,.8-1.54,0-1.3-5.01-3.01-6.73-8.02-8.02-.8-.21-.8-1.33,0-1.54,5.01-1.3,6.73-3.01,8.02-8.02,.21-.8,1.33-.8,1.54,0,1.3,5.01,3.01,6.73,8.02,8.02,.8,.21,.8,1.33,0,1.54Z"
                        fill={color}/>
                    <path
                        d="M22.32,109.82c-3.55,.92-4.77,2.14-5.69,5.69-.15,.57-.95,.57-1.09,0-.92-3.55-2.14-4.77-5.69-5.69-.57-.15-.57-.95,0-1.09,3.55-.92,4.77-2.14,5.69-5.69,.15-.57,.95-.57,1.09,0,.92,3.55,2.14,4.77,5.69,5.69,.57,.15,.57,.95,0,1.09Z"
                        fill={color}/>
                    <path
                        d="M19.95,84.12c-5.49,1.42-7.38,3.31-8.8,8.8-.23,.88-1.46,.88-1.69,0-1.42-5.49-3.31-7.38-8.8-8.8-.88-.23-.88-1.46,0-1.69,5.49-1.42,7.38-3.31,8.8-8.8,.23-.88,1.46-.88,1.69,0,1.42,5.49,3.31,7.38,8.8,8.8,.88,.23,.88,1.46,0,1.69Z"
                        fill={color}/>
                </g>
            </g>
        </svg>
    )
}
