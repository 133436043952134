import React from 'react'
import { useTranslation } from "react-i18next";
import { useForm } from 'react-hook-form'

import { useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import * as yup from 'yup'

import CustomButton from 'components/CustomButton'
import CustomTextField from 'components/CustomTextField'
import { useAuth } from 'hooks/useAuth'

const Schema = yup.object().shape({
  email: yup.string()
      .required(process.env.REACT_APP_ACCOUNT_ID === '26' ? "required field" : "必須項目です")
      .email(process.env.REACT_APP_ACCOUNT_ID === '26' ? "Please enter in email address format" : "メールアドレスの形式で入力してください"),
  password: yup.string()
      .required(process.env.REACT_APP_ACCOUNT_ID === '26' ? "required field" : "必須項目です")
      .min(10, process.env.REACT_APP_ACCOUNT_ID === '26' ? "Please enter at least 10 characters" : "10 文字以上で入力してください"),
})

export default function EmailLogin({ style, onError }) {
  const { t } = useTranslation();
  const { spacing, palette } = useTheme()
  const { signInWithEmail } = useAuth()
  const { register, handleSubmit, errors } = useForm({
    defaultValues: Schema.cast(),
    validationSchema: Schema,
  })

  const onSubmit = data => {
    signInWithEmail(data).catch(onError)
  }

  return (
    <div style={{ padding: spacing(3, 0), ...style }}>
      <CustomTextField
        id="email"
        label={t("Word.EmailAddress")}
        register={register}
        placeholder="runtrip@example.com"
        error={errors.email}
      />
      <CustomTextField
        id="password"
        type="password"
        label={t("Word.Password")}
        register={register}
        placeholder={t("Word.With10OrMoreCharacters")}
        error={errors.password}
      />
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <CustomButton
          style={{ margin: spacing(4, 0), width: 210 }}
          onClick={handleSubmit(onSubmit)}
        >
            {t("Word.Login")}
        </CustomButton>
        <a href="https://runtrip.jp/profile/forgetpswd" target="_blank" rel="noopener noreferrer">
          <Typography style={{ color: palette.link }} variant="body1">
              {t("Word.ForgotPassword")}
          </Typography>
        </a>
      </div>
    </div>
  )
}
