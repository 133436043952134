import 'moment/locale/ja'
import { useTranslation } from "react-i18next";

import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'

import CustomButton from 'components/CustomButton'
import SectionHeader from 'components/SectionHeader'
import { useEvent } from 'hooks/useEvent'
import MainImage from 'components/MainImage'

export default function EntryComplete() {
  const { t } = useTranslation();
  const { id } = useParams()
  const { spacing, palette } = useTheme()
  const [event, setEvent] = useState(null)
  const { getEvent } = useEvent()

  moment.locale(process.env.REACT_APP_ACCOUNT_ID === '26' ? 'en-ca' : "ja")

  useEffect(() => {
    getEvent(id).then(eventResource => setEvent(eventResource.event))
  }, [id, getEvent])

  if (!event) {
    return null
  }

  return (
      <div style={{ background: palette.background.paper }}>
        <SectionHeader primary={t("Heading.AppInstallationRequest")} />
        <div style={{ padding: spacing(3) }}>
          <div
            style={{
              padding: spacing(3, 0),
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography variant='body2' style={{ marginBottom: spacing(6) }}>
              {moment(event.start_time).format(process.env.REACT_APP_ACCOUNT_ID === '26' ? 'dddd, MMMM DD, YYYY' : 'YYYY年MM月DD日(ddd)') +
              ' - ' +
              moment(event.end_time).format(process.env.REACT_APP_ACCOUNT_ID === '26' ? 'dddd, MMMM DD, YYYY' : 'YYYY年MM月DD日(ddd)')}
              <br />
              {event.title}
              <br />
                {t("Word.AppliedToParticipateInTheEvent")}
            </Typography>
            <Typography variant='body2' align='center'>
                {t("Message.EntryComplete.InstallationIsRequired")}
            </Typography>
          </div>
        </div>
        <SectionHeader primary={t("Message.EntryComplete.ForFirstTimeParticipants")} />
        <div style={{ padding: spacing(3) }}>
          <div
            style={{
              padding: spacing(3, 0),
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography style={{ fontWeight: 'bold', marginBottom: spacing(3) }} variant='body1'>
                {t("Message.EntryComplete.IfYouDontHaveMeasuringEquipment")}
            </Typography>
              <MainImage
                  src={process.env.REACT_APP_ACCOUNT_ID === '26' ? '/images-static/easy_measurement_en.png' : '/images-static/easy_measurement.png'}
                  alt='' type='description'/>
            <div
              style={{
                padding: spacing(3, 0),
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography variant='body2' style={{ marginBottom: spacing(6) }}>
                  {t("Message.EntryComplete.AppInstallationGuide")}
              </Typography>
              <CustomButton
                style={{ marginBottom: spacing(6) }}
                onClick={() => window.open(`https://runtrip-web.onelink.me/Pprx/EventDetail`)}
                arrow
              >
                  {t("Word.InstallRuntripForFree")}
              </CustomButton>
            </div>
          </div>
          <div
            style={{
              padding: spacing(3, 0),
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography style={{ fontWeight: 'bold', marginBottom: spacing(3) }} variant='body1'>
                {t("Heading.EntryComplete.IfYouHaveMeasuringEquipment")}
            </Typography>
              <MainImage
                  src={process.env.REACT_APP_ACCOUNT_ID === '26' ? '/images-static/connect_device_en.png' : '/images-static/connect_device.png'}
                  alt='' type='description'/>
            <div
              style={{
                padding: spacing(3, 0),
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography variant='body2' style={{ marginBottom: spacing(6) }}>
                  {t("Message.EntryComplete.IfYouHaveMeasuringEquipment")}
              </Typography>
              <CustomButton
                style={{ marginBottom: spacing(6) }}
                onClick={() => window.open(`https://runtrip-web.onelink.me/Pprx/EventDetail`)}
                arrow
              >
                  {t("Word.InstallRuntripForFree")}
              </CustomButton>
              <Typography style={{ color: palette.link }} variant='body1'>
                <Typography
                  style={{ marginBottom: spacing(3), color: palette.link, cursor: 'pointer' }}
                  onClick={() => window.open(`https://support.runtrip.jp/hc/ja/articles/360055504494`)}
                >
                    {t("Message.EntryComplete.AboutHowToConnectMeasuringDevicesWithTheApp")}
                </Typography>
              </Typography>
            </div>
          </div>
        </div>

        <SectionHeader primary={t("Heading.EntryComplete.ForThoseWhoHaveParticipatedInTheEvent")} />
          <MainImage
              src={process.env.REACT_APP_ACCOUNT_ID === '26' ? '/images-static/confirm_entry_en.png' : '/images-static/confirm_entry.png'}
              alt='' type='description'/>
        <div style={{ padding: spacing(3) }}>
          <div
            style={{
              padding: spacing(3, 0),
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography variant='body2' style={{ marginBottom: spacing(6) }}>
                {t("Message.EntryComplete.OpenTheRuntripAppCndCheckHistory")}
            </Typography>
            <CustomButton
              style={{ marginBottom: spacing(6) }}
              onClick={() => window.open(`https://runtrip-web.onelink.me/Pprx/EventDetail`)}
              arrow
            >
                {t("Word.OpenInApp")}
            </CustomButton>
          </div>
        </div>
      </div>
  )
}
